const songs = [
  {
    songId: '45cYwDMibGo',
    bpm: 83,
  },
  {
    songId: 'iL-jC7XyLeo',
    bpm: 98,
  },
  {
    songId: 'I_izvAbhExY',
    bpm: 103,
  },
  {
    songId: '5NV6Rdv1a3I',
    bpm: 116,
  },
  {
    songId: '0J2QdDbelmY',
    bpm: 124,
  },
  {
    songId: 'u1xrNaTO1bI',
    bpm: 130,
  },
  {
    songId: 'rY0WxgSXdEE',
    bpm: 110,
  },
  {
    songId: 'LKApCTSVppU',
    bpm: 94,
  },
  {
    songId: 'V0FoYUcq9FQ',
    bpm: 84,
  },
  {
    songId: 'hTWKbfoikeg',
    bpm: 117,
  },
  {
    songId: 'SDTZ7iX4vTQ',
    bpm: 128,
  },
  {
    songId: 'dvgZkm1xWPE',
    bpm: 138,
  },
];

export default songs;